<template>
  <div class="products container-sm mb-3" v-if="page != null">
    <h2 class="h4 mb-4 text-center font-weight-bold">
      {{ page.title }}
    </h2>
    <div v-html="page.content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: null
    };
  },
  watch: {
    "$route.params.page"() {
      this.page = this.$route.params.page;
      this.$emit('updateHead');
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  beforeMount() {
    this.page = this.$route.params.page
  },
  head: {
    title() {
      return {
        inner: this.page.meta.title
      }
    },
    meta() {
      return [
        { n: 'description', c: this.page.meta.description, id: 'desc' }, // id to replace intead of create element
        // Twitter
        { n: 'twitter:title', c: this.page.meta.title },
        { n: 'twitter:description', c: this.page.meta.description},
        // Google+ / Schema.org
        // { ip: 'name', c: this.page.meta.title },
        // { ip: 'description', c: this.page.meta.description },
        // Facebook / Open Graph
        // { p: 'fb:app_id', c: '123456789' },
        { p: 'og:title', c: this.page.meta.title },
        // { p: 'og:image', c: 'https://example.com/image.jpg' },
      ]
    },
    link() {
      return [
        {
          rel: "canonical",
          href: "https://www.topups.com" + this.$route.path,
          id: "canonical"
        }
      ];
    }
  }
};
</script>

<style lang="scss" modular>

</style>
